import * as React from 'react'
import { Column, Row } from 'react-table'
import { DataTable, useDataTable } from 'shared/common'
import { formatCurrency } from 'shared/utils'
import {
  CircularProgress,
  TableCell,
  TableFooter,
  TableRow,
} from '@material-ui/core'
import {
  buildCategoryAmountTree,
  CategoryAmountElement,
} from '../utils/CategoryAmountTree'
import { useCategoryAmountSegments } from '../data/UseCategoryAmountsSegments'

export type CategoryAmountTableProps = ReturnType<
  typeof useCategoryAmountSegments
>[1]

export const CategoryAmountTable = ({
  loading,
  data,
}: CategoryAmountTableProps) => {
  const segments = React.useMemo(() => {
    return data?.categoryAmountsSegments?.map(row => row.name) ?? []
  }, [data])

  const rows = React.useMemo(() => {
    if (!data?.categoryAmountsSegments) {
      return []
    }
    return buildCategoryAmountTree(data?.categoryAmountsSegments)
  }, [data])

  const columns = React.useMemo<Column<CategoryAmountElement>[]>(() => {
    const result: Column<CategoryAmountElement>[] = [
      {
        id: 'name',
        Header: 'Categoría',
        accessor: ({ name }) => name,
        Cell: ({ value, row }) => (
          <DepthCell type="string" depth={row.depth}>
            {value}
          </DepthCell>
        ),
      },
    ]

    if (segments.length) {
      segments.forEach((segment, index) => {
        result.push({
          id: `segment-${index}`,
          disableSortBy: true,
          Header: () => (
            <span className="block w-100 text-right">{segment}</span>
          ),
          accessor: ({ segments }) => segments[segment],
          Cell: ({ value, row }) => (
            <DepthCell type="number" depth={row.depth}>
              {formatCurrency(parseFloat(value))}
            </DepthCell>
          ),
        })
      })
    }

    if (segments.length > 1) {
      result.push({
        id: 'segment-total',
        disableSortBy: true,
        Header: () => <span className="block w-100 text-right">Total</span>,
        accessor: ({ segments }) => segments.total,
        Cell: ({ value, row }) => (
          <DepthCell type="number" depth={row.depth}>
            {formatCurrency(parseFloat(value))}
          </DepthCell>
        ),
      })
    }

    return result
  }, [segments])

  const tableInstance = useDataTable({
    pagination: false,
    selectable: false,
    getSubRows: row => row.children,
    columns,
    data: rows,
    initialState: {
      sortBy: React.useMemo(() => [{ id: 'name' }], []),
    },
  })

  const footer = React.useCallback(
    (rows: Row<CategoryAmountElement>[]) => {
      const parentRows = rows.filter(row => !row.depth)
      const segmentTotals: { name: string; amount: number }[] = []
      for (const segment of segments) {
        const amount = parentRows.reduce((sum, row) => {
          return sum + parseFloat(row.original.segments[segment])
        }, 0)
        segmentTotals.push({ name: segment, amount })
      }
      const total = segmentTotals.reduce((sum, { amount }) => sum + amount, 0)

      return (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={2} align="right">
              <span className="block w-100 font-600 text-right text-20">
                Total
              </span>
            </TableCell>
            {segmentTotals.map(segment => {
              return (
                <TableCell key={segment.name} align="right">
                  <span className="block w-100 font-600 text-right text-20">
                    {formatCurrency(segment.amount)}
                  </span>
                </TableCell>
              )
            })}
            {segments.length > 1 ? (
              <TableCell align="right">
                <span className="block w-100 font-600 text-right text-20">
                  {formatCurrency(total)}
                </span>
              </TableCell>
            ) : null}
          </TableRow>
        </TableFooter>
      )
    },
    [segments],
  )

  if (loading) {
    return <CircularProgress></CircularProgress>
  }

  return (
    <DataTable noToolbar={true} footer={footer} {...tableInstance}></DataTable>
  )
}

interface DepthCellProps {
  type: 'number' | 'string'
  depth: number
}

function DepthCell({
  type,
  depth,
  children,
}: React.PropsWithChildren<DepthCellProps>) {
  return (
    <span
      className="block w-100"
      style={{
        fontSize: `${2 - depth * 0.25}rem`,
        fontWeight: 600 - 100 * depth,
        paddingLeft: type === 'string' && `${depth}rem`,
        textAlign: type === 'number' ? 'right' : 'left',
      }}
    >
      {children}
    </span>
  )
}
